module.exports={
  "firebaseSettings": {
    "projectName": "roger-smeby",
    "config": {
      "apiKey": "AIzaSyCjifeYgNxu2jfFkBDhynyC7Sja0mE31_8",
      "authDomain": "roger-smeby.firebaseapp.com",
      "databaseURL": "https://roger-smeby.firebaseio.com",
      "projectId": "roger-smeby",
      "storageBucket": "roger-smeby.appspot.com",
      "messagingSenderId": "106633602067",
      "appId": "1:106633602067:web:38cf5514539a1b222a0be6",
      "measurementId": "G-KVS8MQ4E6P"
    }
  }
}
