import React from 'react';
import {NavLink} from 'react-router-dom';
import { SettingsContext } from '../contexts/SettingsContext';

export default class Logo extends React.Component {
  static contextType = SettingsContext;

  componentDidMount = () => {
    this.context.getSettings();
  }

  render() {
    return (
      <NavLink to="/" exact className="logo">
        {this.context.settings.siteHeading}
      </NavLink>
    );
  }
}
