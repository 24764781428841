import * as React from 'react';
import { AddButton } from '../components/Button';
import DocumentEditor from '../components/DocumentEditor';
import ActivityIndicator from '../components/ActivityIndicator';
import { DocumentsContext } from '../contexts/DocumentsContext';
import { EditingContext } from '../contexts/EditingContext';

export default class DocumentDisplayer extends React.Component {
  state = {
    loading: true,
  };

  static contextType = DocumentsContext;

  componentDidMount = async () => {
    await this.context.getDocumentsForPage(this.props.pageName);
    this.setState({ loading: false });
  };

  toggleAddItem = () => {
    this.context.createDocumentForPage(this.props.pageName);
  };


  moveUp = (documentId) => {
    const { pageName } = this.props;
    let documents = this.context.documents[pageName];
    let document = documents.find(document => document.id === documentId);
    let sortedDocuments = documents.sort((a, b) => a.position - b.position);
    let ourDocumentIndex = documents.findIndex(document => document.id === documentId);
    let closestDocument = sortedDocuments[ourDocumentIndex-1];
    if(closestDocument) {
      this.context.updateDocumentPosition(document, this.props.pageName, closestDocument.position);
      this.context.updateDocumentPosition(closestDocument, this.props.pageName, document.position);
    }
  };

  moveDown = (documentId) => {
    const { pageName } = this.props;
    let documents = this.context.documents[pageName];
    let document = documents.find(document => document.id === documentId);
    let sortedDocuments = documents.sort((a, b) => a.position - b.position);
    let ourDocumentIndex = documents.findIndex(document => document.id === documentId);
    let closestDocument = sortedDocuments[ourDocumentIndex+1];
    if(closestDocument) {
      this.context.updateDocumentPosition(document, this.props.pageName, closestDocument.position);
      this.context.updateDocumentPosition(closestDocument, this.props.pageName, document.position);
    }
  };

  deleteDocument = (documentId) => {
    let documents = this.context.documents;
    const documentToDeletePosition = this.context.documents.find(
      (document) => document.id === documentId
    ).position;
    documents.forEach((document) => {
      if (document.position > documentToDeletePosition) {
        document.position = document.position - 1;
        this.context.updateDocumentPosition(
          document,
          this.props.documentName,
          document.position
        );
      }
    });
    this.context.deleteDocumentFromPage(this.props.pageName, documentId);
  };

  render() {
    const { loading } = this.state;
    const { pageName } = this.props;
    const { documents, editingDocumentId } = this.context;

    return loading ? (
      <ActivityIndicator />
    ) : (
      <div>
        <div className="data-content-wrapper">
          <EditingContext.Consumer>
            {(context) => (
              <React.Fragment>
                {context.editing ? (
                  <React.Fragment>
                    <div style={{ height: '64px' }} />
                    <AddButton
                      onClick={this.toggleAddItem}
                      label={'Lägg till ny sektion'}
                    />
                  </React.Fragment>
                ) : null}
                {documents && documents[pageName]
                  ? documents[pageName]
                      .sort((a, b) => a.position - b.position)
                      .map((item) => {
                        return (
                          <div
                            className="data-content-wrapper__item"
                            key={item.id}
                            style={context.editing ? { minHeight: '140px' } : {}}
                          >
                            <DocumentEditor
                              item={item}
                              pageName={pageName}
                              moveUp={this.moveUp}
                              moveDown={this.moveDown}
                              editing={editingDocumentId}
                              deleteDocument={this.deleteDocument}
                            />
                          </div>
                        );
                      })
                  : null}
              </React.Fragment>
            )}
          </EditingContext.Consumer>
        </div>
      </div>
    );
  }
}
